<content-loading-animation *ngIf="loadingDetails && !channel"></content-loading-animation>

<!-- New -->
<div class="details-panel new-page" *ngIf="channel && !loadingDetails">
    <!-- Header -->
    <app-details-page-header [tags]="channel.resourceTags" [canEdit]="canEdit(channel)" [canDisable]="canEdit(channel)" (closePage)="close()">
        <zx-adaptive
            title
            [model]="channel"
            [showStatusIcon]="true"
            [showStatusText]="false"
            [showLink]="false"
            [showTag]="false"
            *ngIf="ChannelsTypeGuard.isAdaptive(channel)"
        ></zx-adaptive>
        <zx-delivery
            title
            [model]="channel"
            [showStatusIcon]="true"
            [showStatusText]="false"
            [showLink]="false"
            [showTag]="false"
            *ngIf="ChannelsTypeGuard.isDelivery(channel)"
        ></zx-delivery>
        <zx-failover-channel
            title
            [model]="channel"
            [showStatusIcon]="true"
            [showStatusText]="false"
            [showLink]="false"
            [showTag]="false"
            *ngIf="channel.failover"
        ></zx-failover-channel>
        <zx-mediaconnect-flow
            title
            [model]="channel"
            [showStatusIcon]="true"
            [showStatusText]="false"
            [showLink]="false"
            [showTag]="false"
            *ngIf="channel.mediaconnect"
        ></zx-mediaconnect-flow>
        <zx-medialive-channel
            title
            [model]="channel"
            [showStatusIcon]="true"
            [showStatusText]="false"
            [showLink]="false"
            [showTag]="false"
            *ngIf="channel.medialive"
        ></zx-medialive-channel>
        <app-details-page-header-action-buttons
            action-buttons
            [canEdit]="canEdit(channel)"
            [canEnable]="
                !(
                    !channel.is_enabled &&
                    ((channel.mediaconnect && (channel.source == null || channel.source.id == null)) ||
                        (channel.medialive && (channel.flow == null || channel.flow.id == null) && (channel.source == null || channel.source.id == null)))
                )
            "
            [isEnabled]="!!channel.is_enabled"
            [muted]="channel.active_mute"
            (toggleMute)="toggleMute()"
            [editUrl]="editChannel(channel.name)"
            [canToggle]="canEdit(channel)"
            (toggle)="toggle()"
            (refresh)="refresh()"
            [refreshing]="refreshing"
        ></app-details-page-header-action-buttons>
        <app-details-page-header-drop-down
            drop-down
            [canEdit]="canEdit(channel)"
            [canEnable]="
                !(
                    !channel.is_enabled &&
                    ((channel.mediaconnect && (channel.source == null || channel.source.id == null)) ||
                        (channel.medialive && (channel.flow == null || channel.flow.id == null) && (channel.source == null || channel.source.id == null)))
                )
            "
            [cloneBtn]="true"
            [userPermissions]="
                !resourceTags?.length || (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
            "
            (clone)="cloneChannel(channel.name)"
            (delete)="deleteChannel()"
            (muteUntil)="muteUntil($event)"
            [isEnabled]="!!channel.is_enabled"
            [editUrl]="editChannel(channel.name)"
            [muted]="channel.active_mute"
            (toggleMute)="toggleMute()"
            [canToggle]="canEdit(channel)"
            (toggle)="toggle()"
            [altActionBtn]="recoveryState"
            (altAction)="toggleDR()"
        ></app-details-page-header-drop-down>
    </app-details-page-header>
    <!-- Content -->
    <spinner-animation *ngIf="!isWidgetFullyLoaded"></spinner-animation>
    <div class="details-content" #scrollArea>
        <div class="scroll-content">
            <ng-container *ngIf="isWidgetFullyLoaded">
                <app-layout-buttons
                    *ngIf="!isMobile"
                    [savingLayout]="savingLayout"
                    (saveLayout)="saveLayout()"
                    (resetLayout)="resetLayout()"
                    (revertLayout)="revertLayoutChanges()"
                    [layoutChanged]="layoutChanged"
                    [isLocked]="isLocked"
                    (lockChanged)="lockChanged($event)"
                ></app-layout-buttons>
                <!-- Details-section -->
                <app-details-section
                    #primaryDetailsArea
                    [(detailsList)]="primaryDetails"
                    (detailsListChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isSectionPinned)]="isPrimaryDetailsSectionPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [isPrimaryDetails]="true"
                    [isLocked]="isLocked"
                    (refreshPage)="refresh()"
                    class="primary"
                    [ngClass]="{ sticky: isPrimaryDetailsSectionPinned }"
                ></app-details-section>
                <app-details-section
                    #secondaryDetailsArea
                    [(detailsList)]="secondaryDetails"
                    (detailsListChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isSectionPinned)]="isSecondaryDetailsSectionPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [isLocked]="isLocked"
                    [(isSectionHidden)]="isSecondaryDetailsSectionHidden"
                    (isSectionHiddenChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [ngClass]="{
                        sticky: isSecondaryDetailsSectionPinned || (isSecondaryDetailsSectionHidden && (isPrimaryDetailsSectionPinned || isWidgetHeaderPinned)),
                        hidden: isSecondaryDetailsSectionHidden
                    }"
                    [ngStyle]="{
                        'top.px':
                            isPrimaryDetailsSectionPinned && (isSecondaryDetailsSectionPinned || isSecondaryDetailsSectionHidden)
                                ? primaryDetailsAreaHeight - 1
                                : !isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionHidden
                                ? 40
                                : -1,
                        'z-index': constants.zindexValues.second
                    }"
                >
                </app-details-section>
                <!-- Alerts -->
                <div>
                    <app-channel-alerts [channel]="channel" [canEdit]="canEdit(channel)"></app-channel-alerts>
                </div>
                <!-- Tabs -->
                <app-widget-section-header
                    [(widgetHeaders)]="widgetHeaders"
                    (widgetSelectedChange)="onWidgetSelectChange($event)"
                    [(isSectionPinned)]="isWidgetHeaderPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isMultiSelect)]="isMultiSelect"
                    (isMultiSelectChange)="multiSelectChanged($event)"
                    [multiWidgetHeaders]="multiWidgetHeaders"
                    [isLocked]="isLocked"
                    [isMobile]="isMobile"
                    [ngClass]="{
                        sticky: isWidgetHeaderPinned
                    }"
                    [ngStyle]="{
                        'top.px':
                            isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionPinned
                                ? primaryDetailsAreaHeight + secondaryDetailsAreaHeight - 1
                                : isPrimaryDetailsSectionPinned && !isSecondaryDetailsSectionPinned
                                ? primaryDetailsAreaHeight - 1
                                : !isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionPinned
                                ? secondaryDetailsAreaHeight - 1
                                : -1,
                        'z-index': constants.zindexValues.first
                    }"
                >
                </app-widget-section-header>
                <!-- Content -->
                <app-widget-section
                    [(widgets)]="widgets"
                    (widgetsChange)="onWidgetsLayoutChange($event)"
                    [isMultiSelect]="isMultiSelect"
                    [isLocked]="isLocked"
                ></app-widget-section>
            </ng-container>
        </div>
    </div>
</div>
