import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    HostListener,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef,
    inject
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, interval, Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

// Services
import { ChannelsService } from "../channels.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { ResizeService } from "../../../services/resize.service";

// Models
import { Constants } from "../../../constants/constants";
import { AdaptiveChannel, ChannelTypes, DeliveryChannel, FailoverChannel, MediaLiveChannel } from "../channel";
import { Broadcaster, MediaConnectFlow, Source, Tag, UserPermissions } from "../../../models/shared";

import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { SourcesService } from "../../sources/sources.service";
import { GraphsService } from "src/app/services/graphs.service";
import { urlBuilder } from "@zixi/shared-utils";
import { RecoveryState } from "src/app/models/shared";
import { ChannelLayouts } from "./channel.layout";
import { NavigationService } from "src/app/components/navigation/navigation.service";
import { TagsService } from "../../configuration/tags/tags.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { DisasterRecoveryDialogComponent } from "src/app/components/shared/modals/disaster-recovery-dialog/disaster-recovery-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChannelsTypeGuard } from "src/app/utils/type-guards/channels-type-guard";

@Component({
    selector: "app-channel",
    templateUrl: "./channel.component.html"
})
export class ChannelComponent extends ChannelLayouts implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("primaryDetailsArea", { read: ElementRef }) primaryDetailsArea: ElementRef;
    @ViewChild("secondaryDetailsArea", { read: ElementRef }) secondaryDetailsArea: ElementRef;

    @HostListener("window:resize", [])
    private onResize() {
        this.getDetailsAreaHeights();
    }

    channel: ChannelTypes;
    channelId: number;
    channelName: string | null;
    channelCluster: string | null;
    channelType: string | null;

    RecoveryState = RecoveryState;
    recoveryState: RecoveryState;

    failoverSource?: Source = undefined;
    failoverSourceDashboardLink: string;
    resourceTags: Tag[];
    refreshing = false;
    userPermissions: UserPermissions;
    isZixi = false;
    isContentAnalysis = false;
    loadingDetails = true;
    loadingDisasterRecovery = false;

    targetBroadcaster: Broadcaster;
    encoderDisplayName: string | undefined;
    transcodeEncoderOptions = Constants.transcodeEncoderOptions;

    isWidgetFullyLoaded = false;
    isMultiSelect = false;
    initIsMultiSelect;
    widgetsToRemoveOrAdd: { title: string; toHide: boolean }[] = [];

    private channelsSubscription: Subscription;
    private resizeSubscription: Subscription;
    private navSubscription: Subscription;
    private splitterSubscription: Subscription;
    private refreshThumbnailSubscription: Subscription;

    primaryDetails = this.channelPrimaryDetails;
    secondaryDetails = this.channelSecondaryDetails;
    widgets = this.channelWidgets;
    widgetHeaders = this.channelWidgetHeaders;

    ChannelsTypeGuard = ChannelsTypeGuard;

    protected updateDetailContent(title: string) {
        switch (title) {
            // Primary
            case this.translatedNames.TAGS:
                return {
                    content: "-",
                    object: this.channel,
                    tags: this.channel.resourceTags,
                    type: "channel",
                    canEdit: () => this.canEdit(this.channel)
                };
            case this.translatedNames.PRIORITY:
                return {
                    content: this.tagsService.isObjectVip(this.channel.resourceTags) ? "Yes" : "No"
                };
            case this.translatedNames.PROCESSING_CLUSTER:
                return {
                    content: this.channel.processingCluster ? "-" : "",
                    object: this.channel.processingCluster
                };
            case this.translatedNames.ACTIVE_BROADCASTER:
                return {
                    content: this.channel.type === "adaptive" && this.channel.status?.active_broadcaster ? "-" : "",
                    object: this.channel.status?.active_broadcaster,
                    cluster_id: this.channel.broadcaster_cluster_id
                };
            case this.translatedNames.SOURCE_THUMBNAIL:
                return {
                    content: this.failoverSource ? "-" : "",
                    object: this.failoverSource,
                    isThumbnail: true
                };
            case this.translatedNames.AWS_MEDIACONNECT_FLOW:
                return {
                    content:
                        this.channel.medialive && this.channel.flow
                            ? "-"
                            : this.channel.mediaconnect
                            ? this.channelsService.getFlowName(this.channel)
                            : "",
                    object: this.channel.medialive && this.channel.flow ? this.channel.flow : null,
                    link: this.channel.mediaconnect ? this.channelsService.getFlowLink(this.channel) : "",
                    fa_icon: "external-link-alt"
                };

            case this.translatedNames.AWS_MEDIALIVE_CHANNEL:
                return {
                    content: this.channel.medialive && this.channel.arn ? this.channel.name : "",
                    link:
                        this.channel.mediaconnect && this.channel.arn
                            ? this.channelsService.getChannelLink(this.channel)
                            : "",
                    fa_icon: "external-link-alt"
                };
            // Secondary
            case this.translatedNames.TYPE:
                return {
                    content: this.translate.instant(this.channelsService.processChannelType(this.channel).toUpperCase())
                };
            case this.translatedNames.ALTERNATIVE_CHANNEL:
                return {
                    content: this.channel.altChannel ?? "",
                    object: this.channel
                };
            case this.translatedNames.SOURCE:
                return {
                    content:
                        this.channel.type === "adaptive" &&
                        this.channel.is_transcoding &&
                        this.channel.bitrates[0]?.source
                            ? "-"
                            : "",
                    object:
                        this.channel.type === "adaptive" &&
                        this.channel.is_transcoding &&
                        this.channel.bitrates[0]?.source
                            ? (this.channel as AdaptiveChannel)?.bitrates[0]?.source
                            : null
                };
            case this.translatedNames.SLATE_SOURCE:
                return {
                    content:
                        this.channel.type === "adaptive" && this.channel.is_transcoding && this.channel.slateSource
                            ? "-"
                            : "",
                    object: (this.channel as AdaptiveChannel)?.slateSource
                };
            case this.translatedNames.MEDIACONNECT_SOURCE:
                return {
                    content: (this.channel.mediaconnect || this.channel.medialive) && this.channel.source ? "-" : "",
                    object: (this.channel as MediaConnectFlow | MediaLiveChannel)?.source
                };
            case this.translatedNames.REGION:
                return {
                    content: this.channel.mediaconnect ? this.channelsService.getAWSRegionName(this.channel.region) : ""
                };
            case this.translatedNames.MODE:
                return {
                    content:
                        this.channel.type === "adaptive" && this.channel.is_transcoding
                            ? this.translate.instant("TRANSCODING")
                            : this.channel.type === "adaptive" && !this.channel.is_transcoding
                            ? this.translate.instant("EXISTING_SOURCES")
                            : ""
                };
            case this.translatedNames.PROTOCOLS:
                return {
                    content: this.channel.type === "adaptive" ? this.getProtocols() : ""
                };
            case this.translatedNames.ALERTING_PROFILE:
                return {
                    content: this.channel.alertingProfile?.name ?? "",
                    link:
                        "/" +
                        this.constants.urls.configuration.eventsManagement +
                        "/" +
                        this.channel.alertingProfile?.id
                };
            case this.translatedNames.LOG_HLS_AD_MARKERS:
                return {
                    content:
                        this.channel.adaptive && this.channel.log_scte
                            ? this.translate.instant("ENABLED")
                            : this.channel.adaptive && !this.channel.log_scte
                            ? this.translate.instant("DISABLED")
                            : ""
                };
            case this.translatedNames.CLOSED_CAPTION_PRESERVE:
                return {
                    content:
                        this.channel.type === "adaptive" &&
                        this.channel.is_transcoding &&
                        (this.channel as AdaptiveChannel).copy_closed_captions
                            ? this.translate.instant("ENABLED")
                            : this.channel.type === "adaptive" &&
                              this.channel.is_transcoding &&
                              !(this.channel as AdaptiveChannel).copy_closed_captions
                            ? this.translate.instant("DISABLED")
                            : ""
                };
            case this.translatedNames.IGNORE_TRANSCODE_THRESHOLDS:
                return {
                    content:
                        this.channel.type === "adaptive" &&
                        this.channel.is_transcoding &&
                        (this.channel as AdaptiveChannel).ignore_transcode_thresholds
                            ? this.translate.instant("ENABLED")
                            : this.channel.type === "adaptive" &&
                              this.channel.is_transcoding &&
                              !(this.channel as AdaptiveChannel).ignore_transcode_thresholds
                            ? this.translate.instant("DISABLED")
                            : ""
                };
            case this.translatedNames.HITLESS_PRIORITY_UPGRADE:
                return {
                    content:
                        this.channel.failover && this.channel.failoverSource?.allow_failover_priority_upgrade
                            ? this.translate.instant("ENABLED")
                            : this.channel.failover && !this.channel.failoverSource?.allow_failover_priority_upgrade
                            ? this.translate.instant("DISABLED")
                            : ""
                };
            case this.translatedNames.FAILOVER_MERGE_MODE:
                return {
                    content:
                        this.channel.failover && this.channel.failoverSource?.merge_mode === "none"
                            ? this.translate.instant("NON_HITLESS")
                            : this.channel.failover && this.channel.failoverSource?.merge_mode === "rtp"
                            ? this.translate.instant("SMPTE_2022-7_HITLESS")
                            : this.channel.failover && this.channel.failoverSource?.merge_mode === "content"
                            ? this.translate.instant("CONTENT_HITLESS")
                            : ""
                };
            case this.translatedNames.MERGE_SEARCH_WINDOW:
                return {
                    content:
                        this.channel.failover && this.channel.failoverSource?.merge_mode !== "none"
                            ? this.channel.failoverSource?.latency
                            : "",
                    unit: "ms"
                };
            case this.translatedNames.OFFLINE_FAILOVER_WINDOW:
                return {
                    content:
                        this.channel.failover && this.channel.failoverSource?.merge_mode === "none"
                            ? this.channel.failoverSource?.latency
                            : "",
                    unit: "ms"
                };
            case this.translatedNames.CONTENT_ANALYSIS:
                return {
                    content:
                        this.channel.failover && this.channel.failoverSource?.content_analysis
                            ? this.translate.instant("ENABLED")
                            : this.channel.failover && !this.channel.failoverSource?.content_analysis
                            ? this.translate.instant("DISABLED")
                            : ""
                };
            case this.translatedNames.TARGET_BROADCASTER_S:
                return {
                    content:
                        (this.channel as DeliveryChannel).target_broadcaster_id !== null &&
                        (this.channel as DeliveryChannel).target_broadcaster_id !== undefined
                            ? (this.channel as DeliveryChannel).target_broadcaster_id
                            : "",
                    object: this.channel,
                    broadcaster: this.targetBroadcaster
                };
            // Advanced
            case this.translatedNames.CHUNKS:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).hls_chunks
                            ? (this.channel as AdaptiveChannel).hls_chunks
                            : ""
                };
            case this.translatedNames.DURATION:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).hls_chunk_duration
                            ? (this.channel as AdaptiveChannel).hls_chunk_duration
                            : ""
                };
            case this.translatedNames.CONVERT_CEA708_TO_WEBVTT:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).webvtt_cc
                            ? this.translate.instant("ENABLED")
                            : ""
                };
            case this.translatedNames.TIMECODE_CONFIGURATION:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).timecode_mode === "none"
                            ? this.translate.instant("NONE")
                            : this.channel.type === "adaptive" &&
                              (this.channel as AdaptiveChannel).timecode_mode === "system"
                            ? this.translate.instant("SYSTEM_TIME")
                            : this.channel.type === "adaptive" &&
                              (this.channel as AdaptiveChannel).timecode_mode === "sei"
                            ? this.translate.instant("SEI_TIMECODES")
                            : ""
                };
            case this.translatedNames.DISABLE_RENDITION_SYNC:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).disable_synchronization
                            ? this.translate.instant("YES")
                            : ""
                };
            case this.translatedNames.ADAPTIVE_STRICT_SEGMENTS:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).strict_chunks
                            ? this.translate.instant("ENABLED")
                            : ""
                };
            case this.translatedNames.FORCE_I_FRAME_ON_SCTE:
                return {
                    content:
                        this.channel.type === "adaptive" &&
                        (this.channel as AdaptiveChannel).force_i_frame_on_scte &&
                        this.channel.is_transcoding
                            ? this.translate.instant("ENABLED")
                            : ""
                };
            case this.translatedNames.SEGMENT_ON_SCTE35:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).segment_on_scte35
                            ? this.translate.instant("ENABLED")
                            : ""
                };
            case this.translatedNames.SKIP_CUE_IN:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).skip_cue_in
                            ? this.translate.instant("ENABLED")
                            : ""
                };
            case this.translatedNames.DROP_FIRST_SEGMENT:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).drop_first_segment
                            ? this.translate.instant("ENABLED")
                            : ""
                };
            case this.translatedNames.SUPPORT_MULTIAUDIO:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).support_multiaudio
                            ? this.translate.instant("ENABLED")
                            : ""
                };
            case this.translatedNames.ASCENDING_PLAYLIST_ORDER:
                return {
                    content:
                        this.channel.type === "adaptive" && (this.channel as AdaptiveChannel).order_ascending
                            ? this.translate.instant("ENABLED")
                            : ""
                };
            case this.translatedNames.BILLING_CREDENTIALS:
                return {
                    content:
                        (this.channel.type === "adaptive" &&
                            this.channel.is_transcoding &&
                            this.canEdit(this.channel) &&
                            (this.channel as AdaptiveChannel).billing_code) ||
                        (this.channel.failover && this.channel.billing_code)
                            ? this.channel.billing_code
                            : "",
                    object: this.channel,
                    copyCodeLabel: this.translate.instant("COPY_CODE"),
                    copyPasswordLabel: this.translate.instant("COPY_PASSWORD"),
                    password: this.channel.failover ? this.channel.billing_password : ""
                };
            case this.translatedNames.ENCODER:
                return {
                    content:
                        this.channel.type === "adaptive" &&
                        (this.channel as AdaptiveChannel).transcode_encoder &&
                        this.channel.is_transcoding
                            ? this.encoderDisplayName
                            : ""
                };
            case this.translatedNames.THREADS:
                return {
                    content:
                        this.channel.type === "adaptive" &&
                        (this.channel as AdaptiveChannel).transcode_threads &&
                        (this.channel as AdaptiveChannel).transcode_encoder === "x264" &&
                        this.channel.is_transcoding
                            ? (this.channel as AdaptiveChannel).transcode_threads
                            : ""
                };
            case this.translatedNames.EXTERNAL_ID:
                return {
                    content:
                        (this.channel.failover || ChannelsTypeGuard.isDelivery(this.channel)) &&
                        this.channel.external_id
                            ? this.channel.external_id
                            : ""
                };
            default:
                return {
                    content: "",
                    statusClass: ""
                };
        }
    }

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private channelsService = inject(ChannelsService);
    private sourcesService = inject(SourcesService);
    private broadcastersService = inject(BroadcastersService);
    private modalService = inject(ModalService);
    private resizeService = inject(ResizeService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private navigationService = inject(NavigationService);
    private changeDetectorRef = inject(ChangeDetectorRef);
    private tagsService = inject(TagsService);
    private ngbModal = inject(NgbModal);

    constructor(
        protected translate: TranslateService,
        protected gs: GraphsService,
        protected sharedService: SharedService,
        protected userService: UsersService
    ) {
        super(translate, gs, userService, sharedService);
        this.route.paramMap.subscribe(async params => {
            this.channelId = urlBuilder.decode(params.get("channelId") ?? "");
            this.channelName = params.get("name");
            this.channelCluster = params.get("cluster");
            this.channelType = params.get("type");

            if (this.channelName && this.channelId && this.channelType) {
                this.failoverSource = undefined;
                this.getResourcesTags();
            }

            switch (this.channelType) {
                case Constants.urls.channelTypes.adaptive:
                case Constants.urls.channelTypes.transcoded:
                    this.channel = this.channelsService.getCachedAdaptiveChannel(this.channelId);
                    break;
                case Constants.urls.channelTypes.delivery:
                    this.channel = this.channelsService.getPassThroughCachedChannelById(this.channelId);
                    break;
                case Constants.urls.channelTypes.mediaconnect:
                    this.channel = this.channelsService.getCachedMediaConnectFlow(this.channelId);
                    break;
                case Constants.urls.channelTypes.medialive:
                    this.channel = this.channelsService.getCachedMediaLiveChannel(this.channelId);
                    break;
                case Constants.urls.channelTypes.failover:
                    this.channel = this.channelsService.getCachedFailoverChannel(this.channelId);
                    this.updateFailoverSource(this.channel);
                    break;
            }

            if (!this.channel) return this.cancel();

            // Set Title
            this.titleService.setTitle("CHANNEL", "", this.channel);
            this.updateAllThings();
        });
    }

    async updateAllThings() {
        if (this.channel.delivery && this.channel.target_broadcaster_id)
            this.targetBroadcaster = await firstValueFrom(
                this.broadcastersService.getTargetBroadcaster(this.channel.target_broadcaster_id)
            );

        this.updateWidgetsToRemoveOrAdd();
        this.addOrRemoveFromWidgets();
        this.addOrRemoveFromDetails();
        this.primaryDetails = this.getUpdatesDetailsContent(this.primaryDetails);
        this.secondaryDetails = this.getUpdatesDetailsContent(this.secondaryDetails);
        this.recoveryState = this.getDisasterRecoveryState();
    }

    updateWidgetsToRemoveOrAdd() {
        this.widgetsToRemoveOrAdd = [
            { title: this.translatedNames.HISTORY, toHide: !this.failoverSource },
            { title: this.translatedNames.CHANGES, toHide: !this.canEdit(this.channel) },
            {
                title: this.translatedNames.BITRATES,
                toHide: !this.channel.adaptive || !(this.channel.bitrates?.length > 0)
            },
            {
                title: this.translatedNames.AD_MARKERS,
                toHide: !this.channel.adaptive || this.channel.mediaconnect || !this.hasSCTE(this.channel)
            },
            { title: this.translatedNames.ANALYSIS, toHide: !this.failoverSource },
            { title: this.translatedNames.STREAM, toHide: !this.failoverSource },
            {
                title: this.translatedNames.SOURCES,
                toHide:
                    !this.failoverSource &&
                    !(this.channel._frontData?.sortableSources && (this.channel.adaptive || this.channel.delivery))
            }
        ];
    }

    protected addOrRemoveFromDetails() {
        const thumbnailIndex = this.primaryDetails.findIndex(
            details => details.title === this.translatedNames.SOURCE_THUMBNAIL
        );
        if (!this.failoverSource) this.primaryDetails[thumbnailIndex].isHidden = true;
        else if (thumbnailIndex !== -1) this.primaryDetails[thumbnailIndex].isHidden = false;

        this.primaryDetails = [...this.primaryDetails];
    }

    async ngOnInit() {
        // local storage
        if (localStorage.getItem("isInterfaceLocked"))
            this.isLocked = localStorage.getItem("isInterfaceLocked") === "true" ? true : false;

        if (localStorage.getItem("isSourceMultiselect"))
            this.isMultiSelect = localStorage.getItem("isSourceMultiselect") === "true" ? true : false;

        this.navSubscription = this.navigationService.toggle$.subscribe(() =>
            setTimeout(() => this.getDetailsAreaHeights(), 0)
        );
        this.splitterSubscription = this.sharedService.splitterResized$.subscribe(() => this.getDetailsAreaHeights());
        this.resizeSubscription = this.resizeService.getCurrentSize.subscribe(x => {
            this.isMobile = x < 4;
        });

        // isContentAnalysis
        this.userService.isContentAnalysis.pipe(take(1)).subscribe(bool => {
            this.isContentAnalysis = bool;
        });

        this.channelsSubscription = this.channelsService.adaptiveChannels.subscribe(channels => {
            if (
                this.channelType &&
                [Constants.urls.channelTypes.adaptive, Constants.urls.channelTypes.transcoded].includes(
                    this.channelType
                )
            ) {
                const adaptiveChannel = channels.find(c => c.name === this.channelName && c.id === this.channelId);
                if (adaptiveChannel) this.channel = adaptiveChannel;
                if (this.channel && this.channel.hasFullDetails) {
                    this.encoderDisplayName = this.transcodeEncoderOptions.find(
                        obj => obj.id === (this.channel as AdaptiveChannel).transcode_encoder
                    )?.name;
                    this.updateAllThings();
                    this.loadingDetails = false;
                }
            }
        });

        this.channelsSubscription = this.channelsService.deliveryChannels.subscribe(channels => {
            if (this.channelType === Constants.urls.channelTypes.delivery) {
                const deliveryChannel = channels.find(c => c.name === this.channelName && c.id === this.channelId);
                if (deliveryChannel) this.channel = deliveryChannel;
                if (this.channel && this.channel.hasFullDetails) {
                    this.updateAllThings();
                    this.loadingDetails = false;
                }
            }
        });

        this.channelsSubscription = this.channelsService.mediaconnectFlows.subscribe(channels => {
            if (this.channelType === Constants.urls.channelTypes.mediaconnect) {
                const mediaconnectFlow = channels.find(c => c.name === this.channelName && c.id === this.channelId);
                if (mediaconnectFlow) this.channel = mediaconnectFlow;
                if (this.channel && this.channel.hasFullDetails) {
                    this.updateAllThings();
                    this.loadingDetails = false;
                }
            }
        });

        this.channelsSubscription = this.channelsService.medialiveChannels.subscribe(channels => {
            if (this.channelType === Constants.urls.channelTypes.medialive) {
                const medialiveChannel = channels.find(c => c.name === this.channelName && c.id === this.channelId);
                if (medialiveChannel) this.channel = medialiveChannel;
                if (this.channel && this.channel.hasFullDetails) {
                    this.updateAllThings();
                    this.loadingDetails = false;
                }
            }
        });

        this.channelsSubscription = this.channelsService.failoverChannels.subscribe(channels => {
            if (this.channelType === Constants.urls.channelTypes.failover) {
                const failoverChannel = channels.find(c => c.name === this.channelName && c.id === this.channelId);
                if (failoverChannel) this.channel = failoverChannel;
                this.updateFailoverSource(this.channel as FailoverChannel);
                if (this.channel && this.channel.hasFullDetails) {
                    this.updateAllThings();
                    this.loadingDetails = false;
                }
            }
        });

        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isZixi = !!(user.is_zixi_admin || user.is_zixi_support_write || user.is_zixi_support);
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // Start Thumbnail Refresh
        this.startThumbnailRefresh();

        // set initial layout
        await this.revertLayoutChanges();
        this.loadingDetails = false;
        this.isWidgetFullyLoaded = true;
        setTimeout(() => this.getDetailsAreaHeights(), 0);
        this.recoveryState = this.getDisasterRecoveryState();
    }

    ngOnDestroy() {
        this.channelsSubscription?.unsubscribe();
        this.navSubscription?.unsubscribe();
        this.splitterSubscription?.unsubscribe();
        this.resizeSubscription?.unsubscribe();
        this.stopThumbnailRefresh();
    }

    ngAfterViewInit(): void {
        this.loadingDetails = false;
        this.changeDetectorRef.detectChanges();
        this.getDetailsAreaHeights();
    }

    async refresh() {
        this.refreshing = true;
        await firstValueFrom(this.channelsService.refreshChannel(this.channel, true));
        this.refreshing = false;
    }

    toggleMute() {
        this.mixpanelService.sendEvent((this.channel.active_mute ? "unmute" : "mute") + " channel");
        this.channelsService.updateChannel(this.channel, {
            muted: !this.channel.active_mute,
            muted_until: null,
            flapping: null
        });
    }

    async muteUntil(date: Date) {
        this.mixpanelService.sendEvent("mute " + "channel" + " until: " + date.toISOString());
        await this.channelsService.updateChannel(this.channel, {
            muted: true,
            muted_until: date.toISOString(),
            flapping: null
        });
    }

    getResourcesTags() {
        // resourceTags
        this.sharedService
            .getResourceTagsByType(
                [Constants.urls.channelTypes.adaptive, Constants.urls.channelTypes.transcoded].includes(
                    this.channelType ?? ""
                )
                    ? "adaptive_channel"
                    : this.channelType === Constants.urls.channelTypes.delivery
                    ? "delivery_channel"
                    : this.channelType === Constants.urls.channelTypes.mediaconnect
                    ? "mediaconnect_flows"
                    : this.channelType === Constants.urls.channelTypes.medialive
                    ? "medialive_channels"
                    : this.channelType === Constants.urls.channelTypes.failover
                    ? "failover_channel"
                    : "resource"
            )
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
                this.updateWidgetsToRemoveOrAdd();
                this.addOrRemoveFromWidgets();
            });
    }

    updateFailoverSource(channel: FailoverChannel, force?: boolean) {
        if (!this.channel.failover) return;

        this.sourcesService
            .refreshSource(channel.failover_source_id, force)
            .pipe(take(1))
            .subscribe(source => {
                this.failoverSourceDashboardLink = this.gs.custom(this.gs.source(source));
                this.failoverSource = source;
                this.updateWidgetsToRemoveOrAdd();
                this.addOrRemoveFromWidgets();
                this.addOrRemoveFromDetails();
            });

        this.refreshThumbnail();
    }

    startThumbnailRefresh() {
        this.refreshThumbnailSubscription = interval(8000).subscribe(() => {
            this.refreshThumbnail();
        });
    }

    stopThumbnailRefresh() {
        this.refreshThumbnailSubscription.unsubscribe();
    }

    refreshThumbnail() {
        if (!this.failoverSource) return;
        this.sourcesService.refreshSourceThumbnail(this.failoverSource.id);
    }

    cancel() {
        this.gotoChannels();
    }

    close() {
        this.gotoChannels();
    }

    gotoChannels() {
        this.router.navigate([Constants.urls.channels]);
    }

    async deleteChannel() {
        // Mediaconnect
        if (this.channel.mediaconnect) {
            await this.modalService.confirm(
                "DELETE",
                "CHANNEL",
                async (awsRelease: boolean) => {
                    const id = this.channel.id;
                    const result = await this.channelsService.deleteChannel(this.channel, awsRelease);
                    if (result) {
                        this.mixpanelService.sendEvent("delete channel", { id });
                        this.gotoChannels();
                    } else return false;
                },
                this.channel.name,
                {
                    checkbox: this.translate.instant(
                        "DELETE_MEDIACONNECT_FLOW_IN_ZEN_MASTER_LEAVE_IT_IN_AWS_MEDIACONNECT"
                    )
                }
            );
            //
        } else {
            await this.modalService.confirm(
                "DELETE",
                "CHANNEL",
                async () => {
                    const id = this.channel.id;
                    const result = await this.channelsService.deleteChannel(this.channel);
                    if (result) {
                        this.mixpanelService.sendEvent("delete channel", { id });
                        this.gotoChannels();
                    } else return false;
                },
                this.channel.name
            );
        }
    }

    editChannel(name: string) {
        if (this.channel.mediaconnect && !this.channel.medialive) {
            return urlBuilder.getChannelActionUrl(
                this.channel.id,
                Constants.urls.channelTypes.mediaconnect,
                name,
                "edit"
            );
        } else if (this.channel.adaptive) {
            if (this.channel.is_transcoding)
                return urlBuilder.getChannelActionUrl(
                    this.channel.id,
                    Constants.urls.channelTypes.transcoded,
                    name,
                    "edit"
                );
            else
                return urlBuilder.getChannelActionUrl(
                    this.channel.id,
                    Constants.urls.channelTypes.adaptive,
                    name,
                    "edit"
                );
        } else if (this.channel.delivery) {
            return urlBuilder.getChannelActionUrl(this.channel.id, Constants.urls.channelTypes.delivery, name, "edit");
        } else if (this.channel.failover) {
            return urlBuilder.getChannelActionUrl(this.channel.id, Constants.urls.channelTypes.failover, name, "edit");
        } else if (this.channel.medialive) {
            return urlBuilder.getChannelActionUrl(this.channel.id, Constants.urls.channelTypes.medialive, name, "edit");
        }
    }

    cloneChannel(name: string): void {
        if (this.channel.mediaconnect && !this.channel.medialive) {
            this.router.navigate(
                urlBuilder.getChannelActionUrl(this.channel.id, Constants.urls.channelTypes.mediaconnect, name, "clone")
            );
        } else if (this.channel.adaptive) {
            if (this.channel.is_transcoding)
                this.router.navigate(
                    urlBuilder.getChannelActionUrl(
                        this.channel.id,
                        Constants.urls.channelTypes.transcoded,
                        name,
                        "clone"
                    )
                );
            else
                this.router.navigate(
                    urlBuilder.getChannelActionUrl(this.channel.id, Constants.urls.channelTypes.adaptive, name, "clone")
                );
        } else if (this.channel.delivery) {
            this.router.navigate(
                urlBuilder.getChannelActionUrl(this.channel.id, Constants.urls.channelTypes.delivery, name, "clone")
            );
        } else if (this.channel.medialive) {
            this.router.navigate(
                urlBuilder.getChannelActionUrl(this.channel.id, Constants.urls.channelTypes.medialive, name, "clone")
            );
        } else if (this.channel.failover) {
            this.router.navigate(
                urlBuilder.getChannelActionUrl(this.channel.id, Constants.urls.channelTypes.failover, name, "clone")
            );
        }
    }

    async toggle() {
        let action = "";
        const model = {
            is_enabled: this.channel.is_enabled
        };
        if (this.channel.is_enabled === 1) {
            action = "DISABLE";
            model.is_enabled = 0;
        } else {
            action = "ENABLE";
            model.is_enabled = 1;
        }

        let transcoded: boolean;
        if (this.channel.adaptive && this.channel.is_transcoding) transcoded = true;
        else transcoded = false;

        if (transcoded && this.channel.is_enabled !== 1) {
            await this.modalService.confirm(
                action,
                "CHANNEL",
                async igt => {
                    const m = {
                        is_enabled: true,
                        ignore_transcode_thresholds: igt
                    };
                    const result = await this.channelsService.updateChannel(this.channel, m);
                    this.mixpanelService.sendEvent(
                        this.translate.instant(action).toLowerCase().toLowerCase() + " channel"
                    );
                    return result;
                },
                this.channel.name,
                {
                    checkbox: "IGNORE_TRANSCODE_THRESHOLDS"
                }
            );
        } else {
            await this.modalService.confirm(
                action,
                "CHANNEL",
                async () => {
                    const result = await this.channelsService.updateChannel(this.channel, model);
                    this.mixpanelService.sendEvent(
                        this.translate.instant(action).toLowerCase().toLowerCase() + " channel"
                    );
                    return result;
                },
                this.channel.name
            );
        }
    }

    canEdit(channel: ChannelTypes) {
        if (!channel) return false;
        return this.sharedService.canEditZixiObject(channel, this.resourceTags, this.userPermissions);
    }

    hasSCTE(channel) {
        if (!channel || !channel.status) return false;
        return _.some(channel.status.bitrates, br => !_.isEmpty(br.scte));
    }

    refreshChannelPromise() {
        return firstValueFrom(this.channelsService.refreshChannel(this.channel, true));
    }

    getDisasterRecoveryState() {
        return this.channelsService.getDisasterRecoveryState(this.channel);
    }

    async toggleDR() {
        this.loadingDisasterRecovery = true;

        const modal = this.ngbModal.open(DisasterRecoveryDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });

        modal.componentInstance.objects = [
            {
                id: this.channel.id,
                type: this.channel.failover
                    ? "failover_channel"
                    : this.channel.delivery
                    ? "delivery_channel"
                    : "adaptive_channel"
            }
        ];

        modal.result.then(() => {
            this.recoveryState = this.getDisasterRecoveryState();
            this.loadingDisasterRecovery = false;
        });
    }

    getProtocols() {
        const protocols: string[] = [];
        if (this.channel.adaptive) {
            if (!this.channel.disable_hls) protocols.push(this.translate.instant("HLS"));
            if (!this.channel.disable_cmaf) protocols.push(this.translate.instant("DASH"));
        }
        return protocols.join(", ");
    }
}
